import SafeSuspenseLoading from '@components/SafeSuspenseLoading'
import isIFrame from '@helpers/misc/isIFrame'
import useIsSpecialLayout from '@hooks/useIsSpecialLayout'
import useIsStore from '@hooks/useIsStore'
import useWebsiteLayout from '@hooks/useWebsiteLayout'
import useInitialData from '@page-components/Layout/useInitialData'
import dynamic from 'next/dynamic'
import React from 'react'

import useNavbarV2 from './NavbarV2/hooks/useNavbarV2'

const NavbarCPG = dynamic(() => import('./NavbarCPG'))
const NavbarRestaurant = dynamic(() => import('./Navbar'))
const NavbarRestaurantV2 = dynamic(() => import('./NavbarV2'))
export interface DefaultLayoutProps {
  children: React.ReactNode
}

export default function DefaultLayout(props: DefaultLayoutProps) {
  const {website} = useInitialData()

  const layout = useWebsiteLayout(website)
  const isStore = useIsStore(website)
  const specialLayout = useIsSpecialLayout()

  const isNavbarV2Active = useNavbarV2()

  const Navbar =
    isStore && layout.headerLayout === 'cpg'
      ? NavbarCPG
      : isNavbarV2Active
        ? NavbarRestaurantV2
        : NavbarRestaurant

  return (
    <div>
      {isIFrame || specialLayout === 'chat' ? null : (
        <SafeSuspenseLoading>
          <Navbar />
        </SafeSuspenseLoading>
      )}
      {props.children}
    </div>
  )
}
