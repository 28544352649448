import useExperiment from '@hooks/useExperiment.experiments'
import useInitialData from '@page-components/Layout/useInitialData'

function useNavbarV2() {
  const branchId = useExperiment('navbar-v2', {allowServerSide: false})
  const {website} = useInitialData()

  return branchId === 'enabled' && website.layout !== 'tiramisu' && website.layout !== 'taco'
}

export default useNavbarV2
